import React from 'react'
import { graphql, Link } from 'gatsby'
import { Zoom, Fade } from 'react-reveal'
import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/common/Layout'
import TeamMember from '../components/common/TeamMember'

import Section from '../components/common/Section'
import bg3 from '../components/2020/media/bg4.svg'

const team = []

export default ({ data }) => {
  const teamMembers = data.team.edges.map(edge => (
    <Col md={6} key={edge.node.name}>

      <TeamMember {...edge.node} />

    </Col>
  ))
  return (
    <Layout>

      <Section section="section--first" bg={bg3}>
        <header className="page-header">
          <h1 className="page-title display-one">We've got you <span className="outline">covered!</span></h1>
          <h6>
            <span>
              Ask us anything:{' '}
              <a href="mailto:devday@turbinekreuzberg.com">
                devday@turbinekreuzberg.com
              </a>
            </span>
          </h6>
        </header>
        <div className="team-members">
          <Row>{teamMembers}</Row>
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query Team {
    team: allTeamJson {
      edges {
        node {
          name
          img
          position
        }
      }
    }
  }
`
