import React from 'react'

export default props => (
  <div className="member">
    <div className="member__image">
      <figure
        style={{
          overflowY: 'hidden',
          height: 445,
        }}
      >
        <img src={props.img} width="445" height="445" />
      </figure>
    </div>
    <div className="member__info">
      <h6 className="member__name">{props.name}</h6>
      <p className="member__title sub-head-2">{props.position}</p>
    </div>
  </div>
)
